export const StringsEn = {
  messages: {
    required: (field, ...arg) =>
      `The ${StringsEn.fields[field] || field} field is required.`,
    min: (field, ...arg) =>
      `The ${StringsEn.fields[field] || field} must be at least ${
        arg[0] || 0
      } characters long.`,
    max: (field, ...arg) =>
      `The ${StringsEn.fields[field] || field} must be maximum ${
        arg[0] || 0
      } characters long.`,
    email: (field, ...arg) =>
      `The ${StringsEn.fields[field] || field} must be a valid email address.`,
    mobile: (field, ...arg) =>
      `The ${
        StringsEn.fields[field] || field
      } must be a valid 10-digit number.`,
    confirm: (field, ...arg) =>
      `The ${StringsEn.fields[field] || field} must be same as ${
        StringsEn.fields[arg[0]] || arg[0]
      }.`,
    website: (field, ...arg) =>
      `The ${StringsEn.fields[field] || field} must be a valid URL.`,
    uniqueuser: (field, ...arg) =>
      `The ${StringsEn.fields[field] || field} has already been taken.`,
  },
  fields: {
    name: "name",
    first_name: "first name",
    last_name: "last name",
    email: "email",
    mobile: "mobile",
    currentPassword: "current password",
    password: "password",
    cpassword: "re-enter password",
    accept_legal_agreement: "legal agreement",

    __lawyer_PrimaryImageId: "person image",
    __lawyer_SecondaryImageIds: "images",
    __lawyer_Title: "name",
    __lawyer_Category: "category",
    __lawyer_SubCategory1: "sub category1",
    __lawyer_SubCategory2: "sub category2",
    __lawyer_Identifier: "license",
    __lawyer_DisplayDate: "practice since",
    __lawyer_SubTitle: "qualification",
    __lawyer_email_address: "email address",
    __lawyer_phone_number: "phone number",
    __lawyer_address: "address",
    __lawyer_website_link: "website link",
    __lawyer_Property1: "state",
    __lawyer_Property2: "city",
    __lawyer_Property3: "district",
    __lawyer_PropertyList1: "specialization area",
    __lawyer_PropertyList2: "courts",
    __lawyer_PropertyList3: "spoken languages",
    __lawyer_Detail1: "profile detail",
    __lawyer_Price: "hourly price",

    __firm_PrimaryImageId: "firm image",
    __firm_SecondaryImageIds: "images",
    __firm_Title: "name",
    __firm_Category: "category",
    __firm_SubCategory1: "sub category1",
    __firm_SubCategory2: "sub category2",
    __firm_Identifier: "license",
    __firm_DisplayDate: "established in",
    __firm_SubTitle: "sub title or tagline",
    __firm_email_address: "email address",
    __firm_phone_number: "phone number",
    __firm_address: "address",
    __firm_website_link: "website link",
    __firm_Property1: "state",
    __firm_Property2: "city",
    __firm_Property3: "district",
    __firm_PropertyList1: "specialization area",
    __firm_PropertyList2: "courts",
    __firm_PropertyList3: "spoken languages",
    __firm_Detail1: "profile detail",
    __firm_Price: "hourly price",

    __book_PrimaryImageId: "book front",
    __book_SecondaryImageIds: "images",
    __book_Title: "book title",
    __book_Category: "category",
    __book_SubCategory1: "sub category1",
    __book_SubCategory2: "sub category2",
    __book_Identifier: "license",
    __book_DisplayDate: "publish date",
    __book_SubTitle: "author",
    __book_email_address: "email address",
    __book_phone_number: "phone number",
    __book_address: "address",
    __book_website_link: "website link",
    __book_Property1: "state",
    __book_Property2: "city",
    __book_Property3: "district",
    __book_PropertyList1: "tags",
    __book_PropertyList2: "courts",
    __book_PropertyList3: "spoken languages",
    __book_Detail1: "book detail",
    __book_Price: "purchase price",
  },
  profile: {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email Id",
    mobile: "Mobile No",
    currentPassword: "Current Password",
    password: "Password",
    cpassword: "Re-enter Password",
    accept_legal_agreement: "Legal Agreement",

    __lawyer_PrimaryImageId: "Person Image",
    __lawyer_SecondaryImageIds: "Awards, News etc Images",
    __lawyer_Title: "Name of the Lawyer",
    __lawyer_Category: "Category",
    __lawyer_SubCategory1: "Sub Category1",
    __lawyer_SubCategory2: "Sub Category2",
    __lawyer_Identifier: "License",
    __lawyer_DisplayDate: "Practice Since",
    __lawyer_SubTitle: "Qualification",
    __lawyer_email_address: "Email Address",
    __lawyer_phone_number: "Phone Number",
    __lawyer_address: "Address",
    __lawyer_website_link: "Website Link",
    __lawyer_Property1: "State",
    __lawyer_Property2: "City",
    __lawyer_Property3: "District",
    __lawyer_PropertyList1: "Specialization Area",
    __lawyer_PropertyList2: "Courts",
    __lawyer_PropertyList3: "Spoken languages",
    __lawyer_Detail1: "Profile Detail",
    __lawyer_Price: "Hourly price",

    __firm_PrimaryImageId: "Firm Image",
    __firm_SecondaryImageIds: "Awards, News etc Images",
    __firm_Title: "Name of the Firm",
    __firm_Category: "Category",
    __firm_SubCategory1: "Sub Category1",
    __firm_SubCategory2: "Sub Category2",
    __firm_Identifier: "License",
    __firm_DisplayDate: "Established in",
    __firm_SubTitle: "Sub title or Tagline",
    __firm_email_address: "Email Address",
    __firm_phone_number: "Phone Number",
    __firm_address: "Address",
    __firm_website_link: "Website Link",
    __firm_Property1: "State",
    __firm_Property2: "City",
    __firm_Property3: "District",
    __firm_PropertyList1: "Specialization Area",
    __firm_PropertyList2: "Courts",
    __firm_PropertyList3: "Spoken languages",
    __firm_Detail1: "Profile Detail",
    __firm_Price: "Hourly price",

    __book_PrimaryImageId: "Book front",
    __book_SecondaryImageIds: "Book back, preface etc",
    __book_Title: "Book title",
    __book_Category: "Category",
    __book_SubCategory1: "Sub Category1",
    __book_SubCategory2: "Sub Category2",
    __book_Identifier: "License",
    __book_DisplayDate: "Publish date",
    __book_SubTitle: "Author",
    __book_email_address: "Email Address",
    __book_phone_number: "Phone Number",
    __book_address: "Address",
    __book_website_link: "Website Link",
    __book_Property1: "State",
    __book_Property2: "City",
    __book_Property3: "District",
    __book_PropertyList1: "Tags",
    __book_PropertyList2: "Courts",
    __book_PropertyList3: "Spoken languages",
    __book_Detail1: "Book Detail",
    __book_Price: "Purchase price",
  },
  menu: {
    home: "Home",
    about: "About",
    products: "Products",
    contact: "Contact Us",
    account: "My Account",
    login: "Login",
    logout: "Logout",
    signup: "Sign Up",
    cart: "Cart",
  },
  subscription: {
    title: "Newsletter",
    description: "Subscribe to get the latest in updates and news",
    button: "Subscribe Now",
    success:
      "Thank you for subscribing. We look forward to staying in touch and bringing you great content!",
    footer: "We respect your privacy.",
    name: "First Name",
    email: "Your Email",
  },
  news: {
    popular_category: "POPULAR CATEGORY",
    recent_more: "RECENT MORE NEWS",
  },
};
